<template>
	<div class="map-controller">
		<div class="map-tabs bg-white border-bottom">
			<div class="header mb-0">
				<div class="container-fluid">
					<div class="header-body border-0 py-3">
						<div class="row align-items-end">
							<div class="col">
								<h4 class="header-title">Route Searches</h4>
							</div>
							<div class="col-auto">
								<v-datepicker
									range-separator=" to "
									v-model="filter.range"
									placholder="Filter by date"
									range
								></v-datepicker>
								<button
									@click.prevent="applyFilter"
									class="btn btn-primary ml-2 btn-sm"
								>
									Apply
								</button>
							</div>
						</div>
						<!-- / .row -->
					</div>
					<!-- / .header-body -->
				</div>
			</div>
			<div class="container-fluid">
				<div class="row mt-3">
					<div class="col">
						<div>
							<ul class="nav nav-pills mb-1">
								<li class="nav-item">
									<router-link
										:to="{
											name: 'route-searches.filtered-review',
											params: {
												filterType: 'traffic-flow',
											},
										}"
										class="nav-link"
										active-class="active"
									>Traffic Flow</router-link
									>
								</li>

								<li class="nav-item">
									<router-link
										class="nav-link"
										active-class="active"
										:to="{
											name: 'route-searches.filtered-review',
											params: {
												filterType: 'top-origins',
											},
										}"
									>Top Origins</router-link
									>
								</li>

								<li class="nav-item">
									<router-link
										class="nav-link"
										active-class="active"
										:to="{
											name: 'route-searches.filtered-review',
											params: {
												filterType: 'top-destinations',
											},
										}"
									>Top Destinations</router-link
									>
								</li>
							</ul>
						</div>
					</div>
				</div>
			</div>
		</div>

		<VueDeckgl
			:layers="layers"
			:viewState="viewState"
			@click="handleClick"
			:getTooltip="getTooltip"
			@view-state-change="updateViewState"
			ref="vueDeckGl"
		>
			<div id="map" ref="map"></div>
		</VueDeckgl>
	</div>
</template>

<script>
import { h3ToGeo } from 'h3-js'
import { Deck } from '@deck.gl/core'
import { GeoJsonLayer, ArcLayer } from '@deck.gl/layers'
import { HexagonLayer, HeatmapLayer } from '@deck.gl/aggregation-layers'
import mapboxgl from 'mapbox-gl'
import moment from 'moment'
// import VueDeckgl from "vue-deck.gl";
import VueDeckgl from '@/components/core/VueDeckGl.vue'

const MAP_STYLE = 'mapbox://styles/akshuttlers/cl3k94bni001d14npbwn6pz49'

export default {
  props: {
    filterType: [String],
  },
  components: {
    VueDeckgl,
  },
  data() {
    return {
      errorLoading: false,
      loading: false,
      filter: {
        areaHashInMeters: 300,
        range: [
          moment().startOf('month').toDate(),
          moment().endOf('month').toDate(),
        ],
      },
      map: null,
      data: {},
      accessToken: process.env.VUE_APP_MAPBOX_TOKEN,
      mapStyle: MAP_STYLE,
      viewState: {
        latitude: 6.43106879301731,
        longitude: 3.468537700546043,
        zoom: 4,
        bearing: 0,
        pitch: 30,
      },
    }
  },
  created() {
    this.map = null
    this.fetchRouteSearchHistory()
  },
  mounted() {
    this.drawMap()
  },

  watch: {
    $route: function () {
      const startDate = this.$route.query.startDate
      if (startDate) {
        const startDateObj = moment(startDate, 'YYYY-MM-DD').toDate()
        this.filter.range[0] = startDateObj
      }

      const endDate = this.$route.query.endDate
      if (endDate) {
        const endDateObj = moment(endDate, 'YYYY-MM-DD').toDate()
        this.filter.range[1] = endDateObj
      }

      this.$nextTick(() => {
        this.updateMap()
      })
    },
    data() {
      this.$nextTick(() => {
        this.updateMap()
      })
    },
  },

  computed: {
    isTrafficFlow() {
      return this.filterType == 'traffic-flow'
    },
    isTopOrigins() {
      return this.filterType === 'top-origins'
    },
    isTopDestinations() {
      return this.filterType === 'top-destinations'
    },
    layers() {
      return this.getMapLayers()
    },
  },
  methods: {
    getTooltip({ object, layer }) {
      if (!object) return null
      if (this.isTrafficFlow) {
        if (layer && layer.id === 'trips') {
          if (object.OriginHash && object.DestinationHash) {
            return `From ${object.OriginHash} To ${object.DestinationHash}`
          }
        }

        if (object.properties && object.properties.name) {
          return `Area ${object.properties.name}`
        }
      }

      if (this.isTopOrigins || this.isTopDestinations) {
        if (object.points) {
          const label = object.points.map((item) => {
            return item.source.OriginHash
          })

          const count = object.points.map((item) => {
            return item.source.Count
          })

          return `Area ${label.join(', ')} Count: ${count.reduce(
            (i, k) => i + k,
            0
          )}`
        }
      }

      return null
    },
    handleClick({ event, info }) {},
    updateViewState(viewState) {
      this.viewState = {
        ...viewState,
      }
      this.map.jumpTo({
        center: [viewState.longitude, viewState.latitude],
        zoom: viewState.zoom,
        bearing: viewState.bearing,
        pitch: viewState.pitch,
      })
    },
    applyFilter() {
      this.fetchRouteSearchHistory()
    },
    updateMap() {},
    drawMap() {
      const vm = this
      mapboxgl.accessToken = this.accessToken
      this.map = new mapboxgl.Map({
        container: this.$refs.map,
        style: MAP_STYLE,
        interactive: false,
        center: [vm.viewState.longitude, vm.viewState.latitude],
        zoom: vm.viewState.zoom,
        bearing: vm.viewState.bearing,
        pitch: vm.viewState.pitch,
      })
      setTimeout(() => {
        const { latitude, longitude, pitch, bearing, zoom } = this.viewState
        this.viewState = {
          latitude,
          longitude,
          pitch,
          bearing,
          zoom: 12,
          transitionDuration: 3000,
        }
      }, 5000)
    },
    getMapLayers() {
      if (!this.data) return []
      if (!this.data.destination_tally) return []
      if (!this.data.origin_tally) return []

      const layers = []

      const vm = this
      let pointsData = [
        ...this.data.destination_tally,
        ...this.data.origin_tally,
      ]
      pointsData = pointsData.map((i) => {
        const d = h3ToGeo(i.OriginHash)
        return {
          type: 'Feature',
          properties: {
            name: i.OriginHash,
            count: i.Count,
          },
          geometry: {
            type: 'Point',
            coordinates: [d[1], d[0]],
          },
        }
      })

      if (this.isTrafficFlow) {
        const geoJsonLayer = new GeoJsonLayer({
          id: 'trip-areas',
          data: {
            type: 'FeatureCollection',
            features: pointsData,
          },
          // Styles
          filled: true,
          pointRadiusMinPixels: 2,
          pointRadiusScale: 1,
          getPointRadius: vm.filter.areaHashInMeters || 300, // (f) => f.Count,
          getFillColor: [200, 0, 80, 180],
          // Interactive props
          pickable: true,
          autoHighlight: true,
          onClick: (info) => {
            if (info.object) {
              alert(
                `${info.object.properties.name} (${info.object.properties.abbrev})`
              )
            }
          },
        })

        const tripArcs = new ArcLayer({
          id: 'trips',
          data: this.data.origin_to_destination_tally,
          pickable: true,
          // dataTransform: (d) => d.features.filter((f) => f.properties.scalerank < 4),
          // Styles
          getSourcePosition: (f) => {
            const d = h3ToGeo(f.OriginHash)
            return [d[1], d[0]]
          }, // London
          getTargetPosition: (f) => {
            const d = h3ToGeo(f.DestinationHash)
            return [d[1], d[0]]
          },
          getSourceColor: [0, 128, 200],
          getTargetColor: [200, 0, 80],
          getWidth: 2,
        })

        layers.push(geoJsonLayer)
        layers.push(tripArcs)
      }

      if (this.isTopOrigins) {
        const topOriginLayer = new HeatmapLayer({
          id: 'origin-heatmap-layer',
          data: this.data.destination_tally,
          getPosition: (f) => {
            const d = h3ToGeo(f.OriginHash)
            return [d[1], d[0]]
          },
          getWeight: (f) => {
            return f.Count
          },
          aggregation: 'SUM',
        })

        const popularOriginsAreas = new HexagonLayer({
          id: 'popular-origins-layer',
          data: this.data.origin_tally || [],
          pickable: true,
          extruded: true,
          radius: vm.filter.areaHashInMeters || 300,
          elevationScale: 2,
          getPosition: (f) => {
            const d = h3ToGeo(f.OriginHash)
            return [d[1], d[0]]
          },
        })

        // layers.push(popularOriginsAreas);
        layers.push(topOriginLayer)
      }

      if (this.isTopDestinations) {
        const topDestinationLayer = new HeatmapLayer({
          id: 'destination-heatmap-layer',
          data: this.data.destination_tally,
          getPosition: (f) => {
            const d = h3ToGeo(f.OriginHash)
            return [d[1], d[0]]
          },
          getWeight: (f) => {
            return f.Count
          },
          aggregation: 'SUM',
        })

        const popularDestinationsAreas = new HexagonLayer({
          id: 'popular-destinations-layer',
          data: this.data.destination_tally || [],
          pickable: true,
          extruded: true,
          radius: vm.filter.areaHashInMeters || 300,
          elevationScale: 2,
          getPosition: (f) => {
            const d = h3ToGeo(f.OriginHash)
            return [d[1], d[0]]
          },
        })

        // layers.push(popularDestinationsAreas);
        layers.push(topDestinationLayer)
      }

      return layers
    },
    fetchRouteSearchHistory() {
      this.loading = true
      this.errorLoading = false

      const area = this.filter.areaHashInMeters || 300
      let startDate = this.filter.range.length ? this.filter.range[0] : null
      let endDate = this.filter.range.length > 1 ? this.filter.range[1] : null

      if (startDate) {
        startDate = moment(startDate).format('YYYY-MM-DD')
      }

      if (endDate) {
        endDate = moment(endDate).format('YYYY-MM-DD')
      }

      let query = ''

      if (startDate) {
        query = `${query}&startDate=${startDate}`
      }

      if (endDate) {
        query += `&endDate=${endDate}`
      }

      return this.axios
        .get(`/v2/routes/search-history?areaHashInMeters=${area}${query}`)
        .then((res) => {
          this.data = res.data
        })
        .catch(() => {
          this.errorLoading = true
        })
        .finally(() => (this.loading = false))
    },
  },
}
</script>

<style lang="scss">
$primary: #01e069 !default;
$black: #060606 !default;

.map-controller {
  min-height: 100vh;
}

.map-controller > * {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  background-color: transparent;
  box-shadow: 0 3px 0 0px $primary;
  color: $black;
  font-weight: 800;
}

.nav-pills .nav-link,
.nav-pills .show > .nav-link {
  border-radius: 0;
}

.map-tabs.bg-white.border-bottom {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 110px;
  z-index: 1000;
}

#map {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #e5e9ec;
  overflow: hidden;
}
</style>
